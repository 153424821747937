import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Article/articleLayout.js";
import Carousel from "../../../../components/Carousel";
import { graphql } from "gatsby";
import ArticleCarouselItem from "../../../../components/Article/articleCarouselItem";
export const pageQuery = graphql`
 {
  carousel: allFile(filter: {relativeDirectory: {eq:"digital/chuko/carousel"}}, sort: {fields: name, order: ASC}){
      nodes{
        name
        childImageSharp{
          fluid(quality: 100){
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
 }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Chuko's Food and Spices is a brand that makes fast, affordable and tasty food. They also make spices for different sauces and soups.`}</h2>
    <p>{`With Chuko's Food and Spices, it's more than just "eating", it’s delivering joy and happiness with every bite.`}</p>
    <h4><span parentName="h4" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "710px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50.70422535211267%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAxUlEQVQoz61S7QqCQBD0eXuTHqigoKBfiRUFQQVBEkmaQj8MP85zd4Kr6FTSpBaGY4+52dvZNfDnMCo3zApcABVyxVFUbhbkGrLO4WfxMs/49ECdRCDXfuDqgdPoLaDEGlpmrSLFN9BiBGn2Iac9SGuAfDUBLcdgkXz3Q10wFQKhvYPcWiD/hHxjQsyGCB0baZpU+I0eyjjCsdvBeT2Hd/HhHvYIggBxJrWWWwyFZIbMd8C5RK3PbYZStuLntXntX9Ma6XEHDF4TpHdCVKgAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Chuko's Food and Spices",
          "title": "Chuko's Food and Spices",
          "src": "/static/456bb8ae539842d6e1bca18d44dbc9c8/911ab/chuko.png",
          "srcSet": ["/static/456bb8ae539842d6e1bca18d44dbc9c8/911ab/chuko.png 710w"],
          "sizes": "(max-width: 710px) 100vw, 710px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></h4>
    <p>{`I worked on designing a logo that captured the playful and happy nature of the brand especially as their mission statement is “Smile on your face, joy in your tummy”`}</p>
    <Carousel marginBottom={50} mdxType="Carousel">
  {props.data.carousel.nodes.map(image => <ArticleCarouselItem fluid={image.childImageSharp.fluid} mdxType="ArticleCarouselItem" />)}
    </Carousel>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      